 /* {
  background-color: #f9f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

 /* .div {
  background-color: #f9f9fc;
  border: 1px none;
  height: 100%;
  position: relative;
  width: 1920px;
} */

 .header {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 64px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 79%;
}

 .ic-error-outline {
  height: 31px;
  left: 1432px;
  position: absolute;
  top: 24px;
  width: 24px;
}

 .group {
  height: 32px;
  left: 1472px;
  position: absolute;
  top: 16px;
  width: 33px;
}

 .overlap-group {
  height: 32px;
  position: relative;
}

 .img {
  height: 24px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 24px;
}

 .element-status-indicators {
  align-items: center;
  background-color: #3EC63B;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 14px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

 .button-text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

 .icon {
  height: 32px;
  left: 1521px;
  position: absolute;
  top: 16px;
  width: 35px;
}

 .button-text-wrapper {
  align-items: center;
  background-color: #3EC63B;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 16px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

 .element-avatars-circle {
  height: 64px;
  left: 1604px;
  position: absolute;
  top: 0;
  width: 40px;
}

 .overlap {
  background-color: #f3f7fc;
  border-radius: 20px;
  height: 40px;
  position: relative;
  top: 12px;
}

 .text-wrapper {
  color: #3EC63B;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 21px;
  left: 13px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

 .icon-2 {
  height: 24px;
  left: 1572px;
  position: absolute;
  top: 20px;
  width: 24px;
}

 .icons {
  height: 24px;
  left: 24px;
  position: absolute;
  top: 20px;
  width: 120px;
}

 .text-wrapper-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

 .ic-search {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

 .footer {
  background-color: #ffffff;
  height: 64px;
  left: 260px;
  mix-blend-mode: normal;
  position: absolute;
  top: 790px;
  width: 79%;
}

 .support-help-center {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 /* .dashboard {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 260px;
} */

 .overlap-2 {
  background-color: #3EC63B;
  height: 1024px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 260px;
}

 .element-navigations-menus {
  height: 44px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 260px;
}

 .element-navigations-menus-2 {
  height: 44px;
  position: relative;
  width: 198px;
  top:130px
}
.element-navigations-menus-22 {
  height: 44px;
  position: relative;
  width: 88px;
  top:88px
}

 .text-wrapper-3 {
  color: #eeeeee;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

 .img-2 {
  height: 20px !important;
  left: 24px !important;
  position: absolute !important;
  top: 12px !important;
  width: 20px !important;
}

 .overlap-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 88px;
  width: 260px;
}

 .element-navigations-menus-wrapper {
  height: 44px;
  position: relative;
  top: -25px;
}

 .list-item-wrapper {
  height: 44px;
  position: relative;
  width: 260px;
}

 .list-item {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

 .element-navigations-menus-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 198px;
}

 .feather-icon-chevron {
  height: 24px;
  left: 214px;
  position: absolute;
  top: 10px;
  width: 24px;
}

 .overlap-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 260px;
}

 .element-navigations-menus-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 132px;
  width: 260px;
}

.element-navigations-menus-1 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 87px;
  width: 260px;
}

 .element-navigations-menus-5 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 260px;
}

 .element-navigations-menus-6 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 219px;
  width: 260px;
}

 .overlap-group-2 {
  background-color: #3EC63B;
  height: 44px;
  position: relative;
  width: 260px;
}

 .img-wrapper {
  background-color: #3EC63B;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

 .frame-2 {
  height: 39px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}

 .element-navigations-wrapper {
  height: 21px;
  left: 284px;
  position: absolute;
  top: 15px;
  width: 57px;
}

 .element-navigations {
  align-items: center;
  display: inline-flex;
  position: relative;
}

 .link {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

 .overlap-5 {
  height: 66px;
  left: 284px;
  position: absolute;
  top: 130px;
  width: 79%;
}

 .group-2 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 629px;
}

 .table-header {
  color: #3EC63B;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .table-header-2 {
  color: #3EC63B;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .div-wrapper {
  align-items: center;
  background-color: #3EC63B;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 485px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

/*  .glyph-mail-trash {
  height: 36px;
  left: 329px;
  position: absolute;
  top: 15px;
  width: 36px;
} */

 .img-3 {
  height: 36px;
  left: 24px;
  position: absolute;
  top: 15px;
  width: 36px;
}

 .group-3 {
  height: 66px;
  left: 625px;
  position: absolute;
  top: 0;
  width: 919px;
}

 .table-header-3 {
  color: #3EC63B;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .element-status-indicators-2 {
  align-items: center;
  background-color: #3EC63B;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 452px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

 .overlap-group-wrapper {
  height: 66px;
  left: 610px;
  position: absolute;
  top: 0;
  width: 307px;
}

 .overlap-group-3 {
  background-color: #3EC63B;
  height: 66px;
  position: relative;
  width: 305px;
}

 .table-header-4 {
  color: #ffffff;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .element-status-indicators-3 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 52px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 165px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
  width: 39px;
}

 .button-text-2 {
  color: #3EC63B;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

/*  .rectangle {
  background-color: #3EC63B;
  height: 4px;
  left: 284px;
  position: absolute;
  top: 196px;
  width: 1612px;
} */

 .frame-3 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 68px;
  left: 284px;
  position: absolute;
  top: 120px;
  width: 77%;
}

 .frame-4 {
  /* background-image: url(../img/rectangle-8.svg); */
  background-size: 100% 100%;
  height: 36px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 315px;
  border-color: #2f485826;
}

 .table-header-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 6px;
  width: 274px;
}

 .feather-icon-chevron-2 {
  height: 24px;
  left: 286px;
  position: absolute;
  top: 6px;
  width: 24px;
}

 .frame-5 {
  height: 36px;
  left: 347px;
  position: absolute;
  top: 16px;
  width: 351px;
}

 .table-header-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #2f485826;
  border-radius: 4px 0px 0px 4px;
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

 .table-header-6 {
  color: #9e9e9e;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 11px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 5px;
  width: 274px;
}

 .element-icon-button {
  height: 36px;
  left: 315px;
  position: absolute;
  top: 0;
  width: 36px;
}

 .element-button-text {
  align-items: center;
  background-color: #3EC63B;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  display: flex;
  gap: 6px;
  height: 36px;
  left: 1487px;
  padding: 6px 15px;
  position: absolute;
  top: 16px;
  width: 109px;
}

 .icons-ic-outline {
  height: 20px;
  position: relative;
  width: 20px;
}

 .button-text-3 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

 .overlap-6 {
  height: 383px;
  left: 284px;
  position: absolute;
  top: 203px;
  width: 77%;
}

 .group-4 {
  height: 383px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

 .tables-default-wrapper {
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

 .tables-default {
  background-color: #ffffff;
  height: 56px;
}

 .overlap-group-4 {
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  position: relative;
  width: 79%;
}

 .table-header-7 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 15px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  top: 17px;
  width: 483px;
}

 .overlap-7 {
  height: 128px;
  left: 0;
  position: absolute;
  top: 56px;
  /* width: 1310px; */
}

 .bg {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 338px;
  position: absolute;
  top: 56px;
  width: 335px;
}

 .bg-2 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 673px;
  position: absolute;
  top: 56px;
  width: 210px;
}

 .bg-3 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 883px;
  position: absolute;
  top: 56px;
  width: 192px;
}

 .bg-4 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 1075px;
  position: absolute;
  top: 56px;
  width: 235px;
}

 .icons-feather-filter {
  height: 24px;
  left: 637px;
  position: absolute;
  top: 86px;
  width: 24px;
}

 .filter {
  height: 17px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 18px;
}

 .filter-wrapper {
  height: 24px;
  left: 827px;
  position: absolute;
  top: 86px;
  width: 24px;
}

 .icons-feather-filter-2 {
  height: 24px;
  left: 1035px;
  position: absolute;
  top: 86px;
  width: 24px;
}

/*  .row {
  background-image: url(../img/line-5-5.svg);
  background-position: 50% 50%;
  background-size: cover;
  height: 1px;
  left: 354px;
  position: absolute;
  top: 105px;
  width: 283px;
} */

 .row-2 {
  background-image: url(../img/line-5-4.svg);
  background-position: 50% 50%;
  background-size: cover;
  height: 1px;
  left: 689px;
  position: absolute;
  top: 105px;
  width: 138px;
}

 .line-wrapper {
  height: 1px;
  left: 901px;
  position: absolute;
  top: 106px;
  width: 134px;
}

 .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 134px;
}

 .tables-default-2 {
  background-color: #00000012;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 339px;
  position: absolute;
  top: 0;
  width: 334px;
}

 .table-header-8 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

 .ic-arrow-upward {
  height: 20px;
  left: 298px;
  position: absolute;
  top: 18px;
  width: 20px;
}

 .tables-default-3 {
  background-color: #00000012;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 673px;
  position: absolute;
  top: 0;
  width: 210px;
}

 .ic-arrow-upward-px {
  height: 20px;
  left: 174px;
  position: absolute;
  top: 18px;
  width: 20px;
}

 .tables-default-4 {
  background-color: #f4f7fa;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 883px;
  position: absolute;
  top: 0;
  width: 192px;
}

 .table-header-9 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 16px;
  width: 168px;
}

 .icons-feather-filter-3 {
  height: 24px;
  left: 1270px;
  position: absolute;
  top: 84px;
  width: 24px;
}

 .row-3 {
  height: 1px;
  left: 1091px;
  position: absolute;
  top: 105px;
  width: 179px;
}

 .line-2 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 179px;
}

 .tables-default-5 {
  background-color: #f4f7fa;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 1075px;
  position: absolute;
  top: 0;
  width: 235px;
}

 .bg-5 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 339px;
}

 .filter-2 {
  height: 17px;
  left: 305px;
  position: absolute;
  top: 88px;
  width: 18px;
}

 .row-4 {
  height: 1px;
  left: 17px;
  position: absolute;
  top: 105px;
  width: 285px;
}

 .line-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 285px;
}

 .tables-default-6 {
  background-color: #f4f7fa;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 338px;
}

 .line-4 {
  height: 128px;
  left: 338px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

 .line-5 {
  height: 128px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

 .line-6 {
  height: 128px;
  left: 672px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

 .line-7 {
  height: 128px;
  left: 1074px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

 .overlap-8 {
  height: 128px;
  left: 1544px;
  position: absolute;
  top: 56px;
  width: 72px;
}

 .bg-6 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 72px;
}

 .tables-default-7 {
  background-color: #f4f7fa;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 72px;
}

 .table-header-10 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 16px;
  width: 48px;
}

 .line-8 {
  height: 128px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

 .group-wrapper {
  height: 72px;
  left: 0;
  position: absolute;
  top: 256px;
  width: 100%;
}

 .group-5 {
  height: 72px;
  position: relative;
  width: 100%;
}

 .overlap-9 {
  height: 72px;
  left: 1310px;
  position: absolute;
  top: 0;
  width: 306px;
}

 .bg-7 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 234px;
  position: absolute;
  top: 0;
  width: 72px;
}

 .ic-more-vert {
  height: 24px;
  left: 258px;
  position: absolute;
  top: 24px;
  width: 24px;
}

 .tables-default-8 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 235px;
}

 .overlap-group-5 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  position: relative;
  width: 234px;
}

 .group-6 {
  height: 17px;
  left: 15px;
  position: absolute;
  top: 27px;
  width: 14px;
}

 .table-content-wrapper {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 339px;
  position: absolute;
  top: 0;
  width: 334px;
}

 .table-content {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0.1px;
  line-height: 20px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

 .tables-default-9 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 673px;
  position: absolute;
  top: 0;
  width: 210px;
}

 .tables-default-10 {
  background-image: url(../img/bg.svg);
  background-size: 100% 100%;
  height: 72px;
  left: 883px;
  position: absolute;
  top: 0;
  width: 192px;
}

 .tables-default-11 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 1075px;
  position: absolute;
  top: 0;
  width: 235px;
}

 .group-7 {
  height: 29px;
  left: 16px;
  position: absolute;
  top: 23px;
  width: 31px;
}

 .group-8 {
  height: 29px;
  left: 59px;
  position: absolute;
  top: 23px;
  width: 31px;
}

 /* .group-9 {
  height: 29px;
  left: 103px;
  position: absolute;
  top: 23px;
  width: 31px;
} */

 .group-10 {
  height: 29px;
  left: 146px;
  position: absolute;
  top: 23px;
  width: 31px;
}

 .group-11 {
  height: 29px;
  left: 190px;
  position: absolute;
  top: 23px;
  width: 31px;
}

 .overlap-10 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 339px;
}

 .table-content-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0.1px;
  line-height: 20px;
  position: absolute;
  top: 26px;
  width: 165px;
}

 .group-12 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 184px;
  width: 100%;
}

 .group-13 {
  height: 29px;
  left: 16px;
  position: absolute;
  top: 21px;
  width: 31px;
}

 .group-14 {
  height: 29px;
  left: 59px;
  position: absolute;
  top: 21px;
  width: 31px;
}

 .group-15 {
  height: 29px;
  left: 103px;
  position: absolute;
  top: 21px;
  width: 31px;
}

 .group-16 {
  height: 29px;
  left: 146px;
  position: absolute;
  top: 21px;
  width: 31px;
}

 .group-17 {
  height: 29px;
  left: 190px;
  position: absolute;
  top: 21px;
  width: 31px;
}

 .tables-default-12 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  height: 72px;
  left: 883px;
  position: absolute;
  top: 0;
  width: 192px;
}

 .table-content-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0.1px;
  line-height: 20px;
  position: absolute;
  top: 26px;
  width: 171px;
}

 .pagination {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  height: 55px;
  left: 0;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  top: 328px;
  width: 100%;
}

 .overlap-11 {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  height: 55px;
  position: relative;
}

 .ic-last-page {
  height: 20px !important;
  left: 132px !important;
  position: absolute !important;
  top: 18px !important;
  width: 20px !important;
}

 .ic-first-page {
  height: 20px !important;
  left: 16px !important;
  position: absolute !important;
  top: 18px !important;
  width: 20px !important;
}

 .ic-chevron-left {
  height: 20px !important;
  left: 44px !important;
  position: absolute !important;
  top: 18px !important;
  width: 20px !important;
}

 .ic-chevron-right {
  height: 20px !important;
  left: 104px !important;
  position: absolute !important;
  top: 18px !important;
  width: 20px !important;
}

 .frame-instance {
  height: 15px !important;
  left: 1507px !important;
  position: absolute !important;
  top: 21px !important;
  width: 93px !important;
}

 .group-18 {
  height: 24px;
  left: 72px;
  position: absolute;
  top: 16px;
  width: 26px;
}

 .overlap-group-6 {
  background-color: #ffffff;
  border-radius: 5px;
  height: 140px;
  position: relative;
  /* width: 24px; */
}



 .text-wrapper-4 {
  color: #3EC63B;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 16px;
  left: 4px;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 16px;
}

 .frame-6 {
  background-image: url(../img/feather-icon-chevron-down-1.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 206px;
  position: absolute;
  top: 19px;
  width: 20px;
}

 .frame-2-instance {
  left: 234px !important;
  position: absolute !important;
  top: 21px !important;
}

 .design-component-instance-node {
  color: #353535 !important;
}

 .frame-4-instance {
  left: 184px !important;
  position: absolute !important;
  top: 21px !important;
}

 .line-9 {
  height: 128px;
  left: 1308px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 1px;
}

 .bg-8 {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #0000001f;
  height: 72px;
  left: 1309px;
  position: absolute;
  top: 116px;
  width: 235px;
}

 .icons-feather-filter-4 {
  height: 24px;
  left: 1504px;
  position: absolute;
  top: 144px;
  width: 24px;
}

 .row-5 {
  height: 1px;
  left: 1325px;
  position: absolute;
  top: 165px;
  width: 179px;
}

 .rectangle-2 {
  background-color: #f4f7fa;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 56px;
  left: 1309px;
  position: absolute;
  top: 60px;
  width: 235px;
}

 .table-header-11 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1325px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 76px;
  width: 168px;
}
.frame-5 .searchtxt{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #2f485826;
  border-radius: 4px 0px 0px 4px;
  height: 36px;
  left: 0;
  /* position: fixed; */
  top: 0;
  width: 315px}

  .frame-3 .element-button-text-2 {
    display: flex;
    width: 109px;
    height: 36px;
    align-items: center;
    gap: 6px;
    padding: 6px 15px;
    position: absolute;
    top: 16px;
    left: 1362px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 2px solid;
    border-color: #3EC63B;
    color: #3EC63B;
    box-shadow: var(--shadows-contained);
    }
    .frame-3 .element-button-text-2  .button-text
    {
     
      position: relative;
width: fit-content;
margin-top: -2px;
margin-right: -1px;
font-family: "Roboto-Bold", Helvetica;
font-weight: 700;
color: #3EC63B;
font-size: 14px;
letter-spacing: 0.3px;
line-height: 24px;
white-space: nowrap;

    }
 .tableBtn{
font-size:12px;
align-items: center;
background-color: #ffffff;
border: 1px solid;
border-color: #3EC63B;
border-radius: 4px;
box-shadow: var(--shadows-contained);
box-sizing: border-box;
height: 25px;
justify-content: center;
/* position: absolute; */
width: 60px;
cursor:pointer;
}
.tableBtn1{
  cursor: pointer;
}

.tableBtn_order{
  font-size:12px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #3EC63B;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  box-sizing: border-box;
  height: 35px;
  justify-content: center;
  /* position: absolute; */
  width: 95px;
  cursor:pointer;
  }


/* styles.css */

/* Custom styling for DataTable */
.ReactTable {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.ReactTable th,
.ReactTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.ReactTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
}

.ReactTable tr:hover {
  background-color: #f1f1f1;
}

.data_margin{
  margin-left: 20%;
}

.pro_buttons{
  margin-left: 65%;
  display: flex;
  height: 40px;
}
.pro_buttons1{
  /* margin-left: 65%; */
  display: flex;
  height: 40px;
}

.down_tem{
  background-color: #E0E0E0;
  border: #E0E0E0;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;

}

.import_tem{
  background-color: #ffffff;
  /* border: #E0E0E0; */
  border: 1px solid #E0E0E0;
  color: #000000;
  border-radius: 5px;
  margin-left: 10px;
  width: 100px;
  cursor: pointer;
}

.export_tem{
  background-color: #ffffff;
  /* border: #E0E0E0; */
  border: 1px solid #E0E0E0;
  color: #000000;
  border-radius: 5px;
  margin-left: 10px;
  width: 100px;
  cursor: pointer;
}

.create_tem{
  background-color: #3EC63B;
  /* border: #E0E0E0; */
  border: 1px solid #E0E0E0;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 10px;
  width: 100px;
  cursor: pointer;
}

.create_tem1{
  background-color: #3EC63B;
  /* border: #E0E0E0; */
  border: 1px solid #E0E0E0;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 10px;
  /* width: 100px; */
  cursor: pointer;
}

.produc_de{
  margin-left: 20%;
  background-color: #ffffff;
}

.cSpAuu{
  visibility: hidden;
}

.pro_names{
  /* width: 276%; */
  
}

.view_pro{
  background-color: #E0E0E0;
  width: 100%;
}

.act_name{
  margin-left: 85%;

}

.pr_names{
  margin-left: 16%;
    margin-top: 3%;
    width: 82%;
}

.product_detail{
  margin-left: 2%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  width: 97%;
  /* height: 65%; */

}

.itme_img{
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.item_name{
  margin-left: 10px;
}

.stock_details{
  margin-left: 15%;
}
.barcode_details{
  margin-left: 10%;
}
.barvalue_details{
  margin-left: 11%;
}
.img_detils{
  margin-left: 16%;
}
/* .stock_details{
  margin-left: 10%;
} */

.itme_img1{
  height: 2%;
  margin-left: 30%;
  width: 6%;
    border-radius: 5px;
    background-color: green;
    color:#ffffff;
    border: 2px solid green;
}
.itme_img2{
  height: 2%;
  margin-left: 1%;
  width: 6%;
    border-radius: 5px;
    
}



.pro_de{
  margin-top: 10px;
}

.tax_ref_details{ 
  margin-left: 23%;
}


.tax_ref_details1{
  margin-left: 10%; 
}



.vl {
  border-left: 2px solid #E0E0E0;
  height: 80px;
}
.itme_d{
  margin-left: 5px;
}

.pro_de_ref{
  margin-left: 1%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  width: 98%;
  /* display: flex; */
}

.item_view_btn{
  margin-left: 94%;
    border: #ffffff;
    background-color: #ffffff;
}

.item_view_btn1{
  margin-left: 83.3%;
  border: #ffffff;
  background-color: #ffffff;
}

.item_view_btn2{
  margin-left: 87.9%;
    border: #ffffff;
    background-color: #ffffff;
}

.item_view_btn3{
  margin-left: 91%;
  border: #ffffff;
  background-color: #ffffff;
}


.media {
  margin-bottom: 20px;
}

.pro_de_ref {
  border: 1px solid #ccc;
  padding: 10px;
}

.item_view_btn {
  margin-right: 10px;
}

.img_and_vid{
  font-size: 13px;
}

.vll {
  border: 2px solid #E0E0E0;
 
}

.tab_itmes{
  border: 1px solid #E0E0E0;
  padding: 8px;
}

.coll_btn{
  border: 1px solid #3EC63B;
  background-color: #ffffff;
  color: #3EC63B;
  border-radius: 5px;
  margin-left: 20px;

}

.exp_btn{
  border: 1px solid #3EC63B;
  background-color: #ffffff;
  color: #3EC63B;
  border-radius: 5px;
  
  
}

.exp_btn11{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 40%;
  height: 45px;
  text-align: left;
  
  
}


.item_input{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  text-align: left;
}

.item_input1{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 200%;
  height: 35px;
  text-align: left;
}

.item_input3{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 40%;
  height: 35px;
  text-align: left;
}

.add_Row{
  border: 1px solid #3EC63B;
  background-color: #ffffff;
  color: #3EC63B;
  border-radius: 5px;
  height: 40px;
}

.remove_row{
  border: 1px solid #3EC63B;
  background-color: #ffffff;
  color: #3EC63B;
  border-radius: 5px;
  height: 30px;
  margin-left: 30px;
  width: 30px;
}

.key_row{

  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  text-align: left;
  margin-top: 10px;
}

.value_row{

  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
}

.item_input2{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 100%;
  height: 85px;
  text-align: left;
}

.user_m{
  margin-left: 20%;
}

.p_name{
  margin-left: 20px;
}

.user_name{
  height: 10% ;
  padding: 10px;
}

.user_ims{
  width: 80px;
    height: 40px;
    margin-left: 5%;
}

.user_img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.user_first{
  margin-left: 3%;
  margin-top: 30px;
  width: 70px;
}
.user_first1{ 
  margin-left: 3%;
  margin-top: 30px;
}

.user_last{
  margin-left: 3%;
  margin-top: 30px;
}

.users_mr{
    width: 90%;
    height: 35px;
    border-radius: 5px;
    color: #000000;
    border: 1px solid #E0E0E0;
}

.users_first{
  height: 35px;
  border-radius: 5px;
  color: #000000;
  border: 1px solid #E0E0E0;
}

.users_last{
  height: 35px;
  border-radius: 5px;
  color: #000000;
  border: 1px solid #E0E0E0;
}

.img_detils1{

  margin-left: 31%;
  margin-top: 10px;

}

.p_name_store{
  margin-left: 20%;
}

.itme_img2{
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-left: 35%;

}

.choose_import{
  text-align: center;
}
.choose_import1{
  text-align: center;
}
.choose_import2{
  text-align: center;
  margin-left: 35px;
}

.choose_import3{
  text-align: center;
  margin-left: 100px !important;
}

.down_sample{
  text-align: center;
  margin-left: 15px;
}

.img_detils_cat{
  margin-left: 89%;
}

.item_input_cat{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 36%;
  height: 35px;
  text-align: left;
  margin-left: 10px;
}

.item_input_cat1{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 50%;
  height: 109px;
  text-align: left;
  margin-left: 10px;
}

.product_imgs{
  margin-left: 10px;
}

.itme_img11{
  margin-left: 85%;
    width: 6%;
    height: 2%;
    border-radius: 5px;
    background-color: green;
    color: #ffffff;
    border: 2px solid green;
}

.ass_products{
  margin-left: 10px;
}

.selected_pro{
  background-color: #3EC63B;
  border: 1px solid #E0E0E0;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 85%;
  cursor: pointer;
  width: 12%;
  height: 45px;
}

.pro_buttons2{
  /* margin-left: 65%; */
  display: flex;
  height: 40px;
}

.item_input_ass{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 20%;
  height: 35px;
  text-align: left;
  margin-left: 20%;
}

.item_input_ass1{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 80%;
  height: 35px;
  text-align: left;
  margin-left: 20%;
}

.select_manu{
  display: flex;
    width: 20%;
}

.item_input_ass2{
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  width: 80%;
  height: 35px;
  text-align: left;
  margin-left: 20%;
}

.clear_check{
  margin-left: 10%;
  color: #3EC63B;
  cursor: pointer;
}

.delivery_order{
  margin-left: 20%;
}

.dis_play_pro{
  display: flex;
}

.product-name1{
  margin-left: 20%;
}
.product-name{
  width: 40%;
}

.view_pro {
  display: flex;
}

.pr_names {
  flex: 1;
  /* margin-left: 20px; */
}

.pro_names {
  display: flex;
  justify-content: space-between;
}

.p_name {
  font-size: 15px;
  font-weight: bold;
}

.act_name {
  font-size: 18px;
  color: green;
}

.product_detail {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.item_details {
  margin-bottom: 20px;
}

.item_name {
  font-size: 18px;
  font-weight: bold;
}

.itme_img11 {
  background-color: #4CAF50;
  color: white;
  border: none;
  /* padding: 10px 20px; */
  cursor: pointer;
  border-radius: 5px;
}

.itme_img11:hover {
  background-color: #45a049;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove-image:hover {
  background-color: darkred;
}
